import { GatsbyButton } from '@components/atm.button/button.component';
import { SleepDiaryTable } from '@lp-root/src/components/mol.sleep-diary-table/sleep-diary-table.component';
import { SeeSleepDiaryQuery } from '@lp-root/src/data/graphql/hasura/see-diary.query.hasura';
import { Col, FaIcon, Grid, H1, Row } from '@web/atomic';
import { FetchDataErrorPlaceholder, Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { TextShimmerBoxStyled } from '@web/atomic/legacy/mol.shimmer/shimmer.component.style';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { useQueryCustom } from '@web/data/use-query-custom.hook';
import React from 'react';
import WebviewLayout from '../../components/org.layout/webview-layout.component';
import { PageProps } from '../../utils/local-types';

const EditarDiarioPage: React.FunctionComponent<PageProps> = (props) => {
  const { data, error, loading } = useQueryCustom(SeeSleepDiaryQuery);

  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();

  return (
    <WebviewLayout>
      <Grid>
        <Row mt mb>
          <Col xs={12}>
            <H1>Diário do sono</H1>
          </Col>
        </Row>
        <LoadingState loading={loading} error={!!error} data={!!data?.sleep_diary?.length}>
          {/* TODO: UI - ajustar loading */}
          <LoadingState.Shimmer>
            <>
              <Row mt mb>
                <Col xs={12}>
                  <TextShimmerBoxStyled height={'22px'} />
                </Col>
              </Row>
              <Row mt mb>
                <Col xs={12}>
                  <TextShimmerBoxStyled height={'22px'} />
                  <TextShimmerBoxStyled height={'22px'} />
                  <TextShimmerBoxStyled height={'22px'} />
                  <TextShimmerBoxStyled height={'22px'} />
                </Col>
              </Row>
            </>
          </LoadingState.Shimmer>
          <LoadingState.Error>
            <FetchDataErrorPlaceholder error={error} />
          </LoadingState.Error>
          <LoadingState.NoData>
            <Placeholder
              icon={<FaIcon.Table size="9x" />}
              title={'Seu diário está em branco'}
              description={`Quando você preencher seu diário, os seus dados podem ser vistos nessa tela, ok? Preencha 1 vez e veja como fica aqui!`}
            >
              <GatsbyButton onClick={close} loading={waitCloseLoading}>
                Combinado!
              </GatsbyButton>
            </Placeholder>
          </LoadingState.NoData>
          <Row mt mb>
            <SleepDiaryTable data={data && data.sleep_diary} type="edit" />
          </Row>
        </LoadingState>
      </Grid>
    </WebviewLayout>
  );
};

export default EditarDiarioPage;
